<template>
  <div class="visualFormNodeAttachmentTable">
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="attachment-rules"
        fit
        :data="visualFormAttachments"
        style="width: 100%;"
        row-key="keyword"
        :row-class-name="generateUniqueRowClass"
        :show-header="false"
      >
        <el-table-column style="width: 100%">
          <template slot-scope="scope">
            <div class="attachment-row">
              <el-form-item
                class="attachment-row-item attachment-row-item--type"
                :class="classes(scope.row, 'attachment_type', scope.$index)"
                :label="__('Attachment type')"
                :required="true"
              >
                <el-select
                  v-model="scope.row.attachment_type"
                  :placeholder="__('Select type')"
                  default-first-option
                >
                  <el-option
                    v-for="item in mediaOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                :label="__('File url/name')"
                :required="true"
                :error="hasInvalidVariable(scope.row.file_name)"
                class="attachment-row-item attachment-row-item--file"
                :class="invalidClasses(scope.row, 'file_name', scope.$index)"
              >
                <input-variable-popper
                  v-model="scope.row.file_name"
                  :force-reinitialize="true"
                  :is-text-area="false"
                  :include-secure-variables="false"
                  :readonly-text="readonlyText(scope.row)"
                  :placeholder="__('File url')"
                  @input="updateFileUrl(scope.row)"
                />
              </el-form-item>

              <div class="attachment-row-item attachment-row-item--upload">
                <el-button
                  type="primary"
                  icon="el-icon-paperclip"
                  @click="addAttachment(scope.row)"
                  style="width: 80px;"
                />
                <el-button
                  type="default"
                  icon="el-icon-view"
                  @click="showPreview(scope.row)"
                  style="width: 80px;"
                  v-show="false"
                />
              </div>

              <div class="attachment-row-item attachment-row-item--action">
                <span @click="removeVisualFormAttachment(scope.$index)">
                  <i class="el-icon-circle-close"></i>
                </span>
                <span
                  v-show="readonlyText(scope.row)"
                  @click="clearVisualFormAttachment(scope.$index)"
                >
                  <i class="el-icon-refresh-right"></i>
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
    <file-explorer @add-attachment="handleAddAttachment" />
    <file-preview />
  </div>
</template>

<script>
import FileExplorer from "@/views/build/callflow/components/node-type-forms/components/FileExplorer";
import FilePreview from "@/views/build/callflow/components/node-type-forms/components/FilePreview";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import { mapState } from "vuex";
// import draggable from "vuedraggable";
import ElTableDraggable from "element-ui-el-table-draggable";
import VariableChecks from "@/views/mixins/VariableChecks";

export default {
  name: "VisualFormNodeAttachment",
  components: {
    // draggable,
    ElTableDraggable,
    FileExplorer,
    FilePreview,
    InputVariablePopper
  },
  mixins: [VariableChecks],
  props: {
    value: {
      type: Array,
      required: true
    },
    filterDefaultMediaOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const visual_form_attachment = {
      attachment_type: "",
      file_url: "",
      file_name: "",
      file_id: null
    };
    return {
      visual_form_attachment: visual_form_attachment,
      selected_row: null,
      $visual_form_attachments: [{ ...visual_form_attachment }],
      default_media_options: [
        {
          value: "image",
          label: __("Image")
        },
        {
          value: "video",
          label: __("Video"),
          disabled: true
        },
        {
          value: "audio",
          label: __("Audio")
        },
        {
          value: "document",
          label: __("Document")
        },
        {
          value: "sticker",
          label: __("Sticker")
        }
      ]
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    /**
     * based on the filter media options passed, add media options to the select
     * @returns {[{label: string, value: string},{disabled: boolean, label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}]|({label: string, value: string}|{disabled: boolean, label: string, value: string}|{label: string, value: string}|{label: string, value: string}|{label: string, value: string})[]}
     */
    mediaOptions() {
      if (this.filterDefaultMediaOptions.length > 0) {
        return _.filter(this.default_media_options, mediaOption =>
          this.filterDefaultMediaOptions.includes(mediaOption.value)
        );
      }
      return this.default_media_options;
    },
    /**
     * classes
     * @returns {(function(*, *): (string))|*}
     */
    classes() {
      return (row, key, index) => {
        if (index > 0 && !row.attachment_type && !row.file_name) {
          return "empty-row empty-val";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },
    /**
     * visual form attachments
     * @returns {[{file_url: string, attachment_type: string, file_name: string, file_id: null}]}
     */
    visualFormAttachments() {
      return this.$data.$visual_form_attachments;
    },
    /**
     * read only text
     * @returns {function(*): boolean}
     */
    readonlyText() {
      return row => {
        return !!row.file_id;
      };
    },

    /**
     * get error class
     * @returns {(function(*, *): (string|undefined))|*}
     */
    invalidClasses() {
      return (row, key, index) => {
        if (index > 0 && !row.attachment_type && !row.file_name) {
          return "empty-row empty-val";
        }
        let hasError = !row[key] || this.hasInvalidVariable(row[key]);
        if (!hasError) {
          return "no-red-highlight";
        }
      };
    },

    /**
     * has invalid variable
     */
    hasInvalidVariable() {
      return value => {
        let invalidVariables = this.invalidVariableIdsPresentInAValue(
          value,
          true
        );

        return !_.isEmpty(invalidVariables)
          ? __("invalid variable, :invalidVariables", {
              invalidVariables: invalidVariables[0]
            })
          : "";
      };
    }
  },

  methods: {
    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    /**
     * drag start
     */
    onDragStart() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        row.classList.add("grabbing");
      }
    },

    /**
     * drag end
     */
    onDragEnd() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        if (row.classList.contains("grabbing")) {
          row.classList.remove("grabbing");
        }
      }
    },

    /**
     * update file url
     * @param row
     */
    updateFileUrl(row) {
      if (!row.file_id) row.file_url = row.file_name;
    },

    /**
     * add attachment
     * @param row
     */
    addAttachment(row) {
      this.selected_row = row;
      EventBus.$emit(
        "open-node-attachment-modal",
        "node-attachment",
        this.mapAttachmentFileToFilterName(row.attachment_type)
      );
    },

    mapAttachmentFileToFilterName(attachment_type) {
      switch (attachment_type) {
        case "document":
          return "visual-form-document";
        case "image":
          return "visual-form-image";
        case "audio":
          return "visual-form-audio";
        default:
          return "visual-form-all";
      }
    },

    /**
     * handle add attachment
     * @param file_url
     * @param file_id
     * @param file_name
     */
    handleAddAttachment(file_url, file_id, file_name) {
      this.selected_row.file_id = file_id;
      this.selected_row.file_url = file_url;
      this.selected_row.file_name = file_name;
    },

    /**
     * show preview
     * @param row
     */
    showPreview(row) {
      EventBus.$emit(
        "open-file-preview-modal",
        row.file_url,
        row.attachment_type
      );
    },

    /**
     * add new attachment
     */
    addNewVisualFormAttachment() {
      this.$data.$visual_form_attachments.push({
        ...this.visual_form_attachment
      });
    },

    /**
     * clear attachment
     * @param index
     */
    clearVisualFormAttachment(index) {
      this.$data.$visual_form_attachments[index].file_id = null;
      this.$data.$visual_form_attachments[index].file_name = "";
      this.$data.$visual_form_attachments[index].file_url = "";
    },

    /**
     * remove
     * @param index
     */
    removeVisualFormAttachment(index) {
      this.$data.$visual_form_attachments.splice(index, 1);
    },

    /**
     * initialize
     * @param visualFormAttachments
     */
    initializeVisualFormAttachments(visualFormAttachments) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(visualFormAttachments) && visualFormAttachments.length) {
          this.$data.$visual_form_attachments = _.cloneDeep(
            visualFormAttachments
          );
        }
        if (
          _.every(this.$data.$visual_form_attachments, visualFormAttachment => {
            return (
              visualFormAttachment.file_name.length &&
              visualFormAttachment.attachment_type.length
            );
          })
        ) {
          this.addNewVisualFormAttachment();
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeVisualFormAttachments"
    },
    visualFormAttachments: {
      handler: function(visualFormAttachments) {
        this.$nextTick(() => {
          this.$emit("input", _.cloneDeep(visualFormAttachments));
          let invalidVariablesFound = _.some(
            visualFormAttachments,
            visualFormAttachment =>
              this.hasInvalidVariable(visualFormAttachment.file_name)
          );
          this.$emit("invalid-variables", invalidVariablesFound);
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.visualFormNodeAttachmentTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.visualFormNodeAttachmentTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.visualFormNodeAttachmentTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.attachment-row {
  display: flex;
  gap: 3.33%;
  align-items: flex-end;
  width: 100%;

  .attachment-row-item {
    padding-bottom: 10px;

    &.attachment-row-item--file {
      flex: 1 0 50%;
    }

    &.attachment-row-item--upload {
      text-align: center;
    }

    &.attachment-row-item--action {
      flex: 1 0 28px;
      text-align: right;
      padding-bottom: 15px;
      cursor: pointer;
      opacity: 0;
    }
  }

  &:hover {
    .attachment-row-item--action {
      opacity: 1;
    }
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
  }
}

.el-table.attachment-rules {
  ::v-deep .el-table__row + .el-table__row {
    .el-form-item__label {
      display: none !important;
    }
    .attachment-row {
      padding-top: 10px;
    }
  }
}

.attachment-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.visualFormNodeAttachmentTable ::v-deep .el-table::before {
  background-color: white !important;
}

.visualFormNodeAttachmentTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
