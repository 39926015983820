<template>
  <div class="nodeContentButtonsTable">
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="button-rules"
        fit
        :data="nodeContentButtons"
        style="width: 100%;"
        :row-class-name="generateUniqueRowClass"
        :show-header="false"
        :row-key="row => row.uuid"
        :expand-row-keys="expandRowKeys"
        @expand-change="handleExpandChange"
      >
        <el-table-column style="width: 100%">
          <template slot-scope="scope">
            <div class="button-field">
              <el-form-item
                :error="
                  scope.row.content_label_error ||
                    hasInvalidVariable(scope.row.content_label)
                "
                :label="__('Button Label')"
                :required="true"
                class="button-field-item button-field-item--label"
                :class="classes(scope.row, 'content_label', scope.$index)"
              >
                <input-variable-popper
                  v-model="scope.row.content_label"
                  :placeholder="__('Button Label')"
                  :is-text-area="false"
                  force-reinitialize
                  :include-secure-variables="false"
                ></input-variable-popper>
              </el-form-item>
              <el-form-item
                :label="__('Value')"
                :required="true"
                :error="hasInvalidVariable(scope.row.default_value)"
                :class="classes(scope.row, 'default_value', scope.$index)"
                class="button-field-item button-field-item--defaultvalue"
              >
                <input-variable-popper
                  v-model="scope.row.default_value"
                  :is-text-area="false"
                  force-reinitialize
                  :placeholder="__('Value')"
                  :include-secure-variables="false"
                ></input-variable-popper>
              </el-form-item>

              <create-or-select
                :items="singleValuedAndSecureVariables"
                label="variable_name"
                :form-item-label="__('Assign to variable')"
                :form-item-required="true"
                value="variable_id"
                :current_select="currentSelection(scope.row)"
                :placeholder="__('Variable name')"
                :new-item-message="__('new variable')"
                @change="handleChange($event)(scope.row)"
                class="button-field-item button-field-item--variable"
                :class="classes(scope.row, 'variable_name', scope.$index)"
              />

              <div class="button-field-item button-field-item--msg">
                {{ scope.row.msg || "" }}
              </div>

              <div class="button-field-item button-field-item--action">
                <span @click="removeNodeContentButton(scope)">
                  <i class="el-icon-circle-close"></i>
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import ElTableDraggable from "element-ui-el-table-draggable";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import { REPLY_BUTTON, BUTTON_TYPES } from "@/constants/messagingNodeButtons";
import { v4 as uuidv4 } from "uuid";
import VariableChecks from "@/views/mixins/VariableChecks";

const BUTTON_LABEL_MAX_LENGTH = 200;

export default {
  BUTTON_LABEL_MAX_LENGTH,
  components: {
    CreateOrSelect,
    ElTableDraggable,
    InputVariablePopper
  },
  name: "node-content-buttons",
  mixins: [VariableChecks],
  props: {
    value: {
      required: true,
      type: Array
    },
    nodeType: {
      required: false,
      default: "visual_form"
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    gotoOptionsForPostback: {
      required: false,
      type: [Array, Boolean]
    },
    filterButtonTypeOptions: {
      type: Array,
      default: () => [REPLY_BUTTON]
    }
  },
  data() {
    let nodeContentButton = {
      content_id: -1,
      content_label: "",
      content_label_error: "",
      content_type: this.filterButtonTypeOptions[0],
      content_icon: "",
      variable_id: -1,
      variable_name: "",
      default_value: ""
    };

    return {
      nodeContentButton,
      $nodeContentButtons: [{ ...nodeContentButton }],
      $gotoOptions: [],
      button_types: BUTTON_TYPES,
      expandRowKeys: []
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),

    /**
     *
     * @returns {[{variable_id: number, variable_type: string, button_type: *, button_icon: string, default_value: string, variable_name: string, keyword: string, keyword_type: string}]}
     */
    nodeContentButtons() {
      return this.$data.$nodeContentButtons;
    },

    /**
     * get the current variable selected
     * @returns {(function(*): (*))|*}
     */
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    /**
     * get error class
     * @returns {(function(*, *): (string|undefined))|*}
     */
    classes() {
      return (row, key, index) => {
        if (
          index > 0 &&
          !row.content_label &&
          !row.default_value &&
          !row.variable_name
        ) {
          return "empty-row empty-val";
        }
        let hasError = !row[key] || this.hasInvalidVariable(row[key]);
        if (!hasError) {
          return "no-red-highlight";
        }

        return "";
      };
    },

    /**
     * has invalid variable
     */
    hasInvalidVariable() {
      return value => {
        let invalidVariables = this.invalidVariableIdsPresentInAValue(
          value,
          true
        );

        return !_.isEmpty(invalidVariables)
          ? __("invalid variable, ") + invalidVariables[0]
          : "";
      };
    }
  },
  methods: {
    /**
     * generate a unique class for the node content button row
     * @param rowIndex
     * @returns {string}
     */
    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    /**
     * add new variable rule
     */
    addNewNodeContentButton() {
      const newNodeContentButton = _.cloneDeep(this.nodeContentButton);
      newNodeContentButton.uuid = uuidv4();
      this.$data.$nodeContentButtons.push({ ...this.nodeContentButton });
    },

    /**
     * remove variable rule
     * @param row
     * @param $index
     */
    removeNodeContentButton({ row, $index }) {
      this.$data.$nodeContentButtons.splice($index, 1);
      this.$emit("removed-msg-btn", _.get(row, "content_label"));
    },

    /**
     * initialise node content buttons
     * @param nodeContentButtons
     */
    initializeNodeContentButtons(nodeContentButtons) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(nodeContentButtons) && nodeContentButtons.length) {
          this.$data.$nodeContentButtons = _.cloneDeep(nodeContentButtons);
        }
        if (
          !_.some(
            this.$data.$nodeContentButtons,
            nodeContentButton => !nodeContentButton.content_label.length
          )
        ) {
          this.addNewNodeContentButton();
        }
      }
    },

    /**
     * drag start
     */
    onDragStart() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      tables[0].classList.add("grabbing");
    },

    /**
     * drag end
     */
    onDragEnd() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      if (tables[0].classList.contains("grabbing")) {
        tables[0].classList.remove("grabbing");
      }
    },

    /**
     * based on the option changed,
     * @param option
     * @returns {(function(*): void)|*}
     */
    handleChange(option) {
      return row => {
        this.$set(row, "variable_id", option.value);
        this.$set(row, "variable_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    },
    /**
     * handle row expand
     * @param row
     * @param expandedRows
     */
    handleExpandChange(row, expandedRows) {
      this.expandRowKeys = _.map(expandedRows, "uuid");
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeNodeContentButtons"
    },
    nodeContentButtons: {
      handler: function(nodeContentButtons) {
        const expandRowKeys = _.cloneDeep(this.expandRowKeys);
        this.$emit("input", _.cloneDeep(nodeContentButtons));
        this.expandRowKeys = expandRowKeys;
        let invalidVariablesFound = _.some(
          nodeContentButtons,
          nodeContentButton =>
            this.hasInvalidVariable(nodeContentButton.default_value) ||
            this.hasInvalidVariable(nodeContentButton.content_label)
        );
        this.$emit("invalid-variables", invalidVariablesFound);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.nodeContentButtonsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.nodeContentButtonsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.nodeContentButtonsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.button-field {
  display: flex;
  width: 100%;
  gap: 2.5%;
  align-items: flex-end;

  ::v-deep .el-form-item__label {
    font-size: 14px;
    word-break: break-word;
  }

  & .button-field-item {
    padding-bottom: 10px;
  }

  & .button-field-item--title {
    flex: 1 0 40%;
  }

  & .button-field-item--label {
    flex: 1 0 30%;
  }

  & .button-field-item--defaultvalue {
    flex: 1 0 25%;
  }

  & .button-field-item--msg,
  & .button-field-item--action {
    padding-bottom: 15px;
  }

  & .button-field-item--action {
    text-align: right;
    cursor: pointer;
    opacity: 0;
  }

  & .button-field-item--msg {
    min-width: 68px;
    font-size: 0.75rem;
    color: $--color-success;
    margin-left: 10px;
  }

  &:hover {
    .button-field-item--action {
      opacity: 1;
    }
  }
}

.el-table.button-rules {
  ::v-deep .el-table__row + .el-table__row {
    .el-form-item__label {
      display: none !important;
    }

    .button-field {
      padding-top: 10px;
    }
  }
}

.button-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.nodeContentButtonsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.nodeContentButtonsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}

/* hack to remove bottom border appearing on form fields section */
.el-table::before {
  height: 0 !important;
}
</style>
